import { createThumbnailByWidth } from '@services/thumbnail.service';
import { removeAtLastIndex } from '@utils/urlUtil';
import { WP_DOMAIN } from 'src/constants';

export const toObj = (source, key) => {
  return source.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export const getPostMediaUrl = (post) => {
  if (!post?._embedded) {
    return;
  }

  if (!post._embedded['wp:featuredmedia'] || post._embedded['wp:featuredmedia'].length === 0) {
    return;
  }

  return post._embedded['wp:featuredmedia'][0]?.source_url?.replaceAll(`https://vnrebates.net/wp-content/`, `${WP_DOMAIN}/wp-content/`);
};

export const createThumbnailForBrokerList = async (brokers, width = 70, callApi = false) => {
  const listImageInfo = brokers.map((broker) => {
    broker.thumbnail = broker.acf.avatar.url;
    broker.thumbnail = removeAtLastIndex(broker.thumbnail, '-150x150', '');
    broker.thumbnail = removeAtLastIndex(broker.thumbnail, '-150x96', '');
    if (!startsWith(broker.thumbnail, 'https://')) {
      broker.thumbnail = `${WP_DOMAIN}${broker.thumbnail}`;
    }

    const imageInfo = {
      sourceUrl: broker.thumbnail,
      sourceUrlLastModified: broker.modified,
      width: width,
    };

    return imageInfo;
  });

  let thumbnails = {};
  if (callApi) {
    const thumbnailRes = await createThumbnailByWidth(listImageInfo);
    thumbnails = toObj(thumbnailRes.data.content, 'sourceUrl');
  }

  return brokers.map((broker) => {
    broker = { ...broker };

    broker.thumbnail = broker.acf.avatar.url;
    if (!startsWith(broker.thumbnail, 'https://')) {
      broker.thumbnail = `${WP_DOMAIN}${broker.thumbnail}`;
    }

    let sourceUrl = removeAtLastIndex(broker.thumbnail, '-150x150', '');
    sourceUrl = removeAtLastIndex(sourceUrl, '-150x96', '');
    if (thumbnails[sourceUrl] === undefined) {
      return broker;
    }
    broker.thumbnail = thumbnails[sourceUrl].originalUrl
      ? thumbnails[sourceUrl].originalUrl
      : thumbnails[sourceUrl].sourceUrl;
    return broker;
  });
};

export const createThumbnailForTopic = async (topics, width, callApi = false) => {
  const listImageInfo = topics.map((topic) => {
    if (!topic.acf?.icon) {
      return;
    }

    const imageInfo = {
      sourceUrl: topic.acf.icon,
      sourceUrlLastModified: topic.modified,
      width: width,
    };
    return imageInfo;
  });
  const filterListImage = listImageInfo.filter(Boolean);

  let thumbnails = {};

  if (callApi) {
    const thumbnailRes = await createThumbnailByWidth(filterListImage);
    thumbnails = toObj(thumbnailRes.data.content, 'sourceUrl');
  }

  return topics.map((topic) => {
    topic = { ...topic };

    const sourceUrl = topic.acf.icon;
    topic.acf.icon = thumbnails[sourceUrl] ? thumbnails[sourceUrl].originalUrl : sourceUrl;
    return topic;
  });
};

export const createThumbnailForPost = async (posts, width, callApi = false) => {
  // const listImageInfo = posts.map((post) => {
  //   const postMedia = getPostMediaUrl(post);
  //   if (!post?.thumbnail) {
  //     return;
  //   };
  //   if (post?.thumbnail?.url) {
  //     post.thumbnail = post?.thumbnail?.url?.replaceAll('https://vnrebates.net/wp-content/', `${DOMAIN}/wp-content/`);
  //   }
  //   if (!startsWith(post.thumbnail, 'https://')) {
  //     post.thumbnail = `${DOMAIN}${post.thumbnail}`;
  //   }

  //   const imageInfo = {
  //     sourceUrl: postMedia || removeAtLastIndex(post.thumbnail, '-150x150', ''),
  //     sourceUrlLastModified: post.last_modified,
  //     width: width,
  //   };
  //   return imageInfo;
  // });

  // let thumbnails = {};
  // if (callApi) {
  //   const thumbnailRes = await createThumbnailByWidth(listImageInfo);
  //   thumbnails = toObj(thumbnailRes.data.content, 'sourceUrl');
  // }

  return posts.map((post) => {
    post = { ...post };

    if (!post?.logo) {
      return post;
    }
    if (post?.logo?.url) {
      post.thumbnail = post.logo.url;
      return post;
    }

    if (!startsWith(post.logo, 'https://')) {
      post.thumbnail = `${WP_DOMAIN}${post.logo}`;
      return post;
    }

    let modifiedThumbnail = removeAtLastIndex(post.logo, '-150x150', '');
    modifiedThumbnail = modifiedThumbnail?.replace('https://vnrebates.net/wp-content/', `${WP_DOMAIN}/wp-content/`);
    post.thumbnail = modifiedThumbnail;

    return post;
  });
};

const startsWith = (obj, str) => {
  if (obj && obj.startsWith && obj.startsWith(str)) {
    return true;
  } else {
    return false;
  }
};
