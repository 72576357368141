import { getHeaderBannerShow } from '@services/bannerAds.service';
import { toObj } from '@utils/thumbnailUtil';
import { Col, Row } from 'antd';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import styles from './HeaderBanner.module.scss';

const HeaderTop = dynamic(() => import('@components/BannerAds/HeaderTop'), {
  ssr: false,
});

const HeaderBanner = () => {
  const [bannerShow, setBannerShow] = useState({});

  const getBanners = async () => {
    const res = await getHeaderBannerShow({
      codes: ['header_top_1', 'header_top_2', 'header_top_3', 'header_top_4'],
    });

    if (res?.data?.content) {
      const banners = res?.data?.content;
      const bannerData = toObj(banners, 'type');
      setBannerShow(bannerData);
    }
    return;
  };

  useEffect(() => {
    const timeoutSkeleton = setTimeout(() => {
      getBanners();
    }, 1000);

    return () => {
      clearTimeout(timeoutSkeleton);
    };
  }, []);

  return (
    <div className={styles.headerBanner}>
      <Row
        gutter={[
          { xs: 5, sm: 5, md: 10, lg: 10 },
          { xs: 5, sm: 5, md: 10, lg: 10 },
        ]}
      >
        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="header_top_1" bannerShow={bannerShow.header_top_1} />
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="header_top_2" bannerShow={bannerShow.header_top_2} />
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="header_top_3" bannerShow={bannerShow.header_top_3} />
        </Col>

        <Col xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
          <HeaderTop typePosition="header_top_4" bannerShow={bannerShow.header_top_4} />
        </Col>
      </Row>
    </div>
  );
};
export default HeaderBanner;
